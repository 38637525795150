import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBoltLightning } from '@fortawesome/pro-light-svg-icons/faBoltLightning'
import { faHandHoldingDollar } from '@fortawesome/pro-light-svg-icons/faHandHoldingDollar'
import { faBullseyeArrow } from '@fortawesome/pro-light-svg-icons/faBullseyeArrow'
import { faWandMagicSparkles } from '@fortawesome/pro-light-svg-icons'
import { isMobile } from 'react-device-detect'

export default function Why() {
  return (
    <>
      <section className="why py-5 position-relative" id="why">
        <div className="container">
          <h2 className="title pb-lg-4">
            A{' '}
            <strong>
              <i>faster</i>
            </strong>{' '}
            and{' '}
            <strong>
              <u>proven</u>
            </strong>{' '}
            cloud-based CFD design tool
          </h2>
          <div className="row justify-content-center pt-5 pb-4">
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card h-100 has-icon">
                <div className="card-body position-relative">
                  <FontAwesomeIcon
                    icon={faBoltLightning}
                    size="5x"
                    style={{ top: 35, right: 10, opacity: 0.1, zIndex: -1 }}
                    className="position-absolute text-primary"
                  />
                  <h3 className="card-title pb-3">
                    Accelerate Your Time to Market
                  </h3>
                  <ul className="pt-2">
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Get insights within hours using High-Performance
                            Computing infrastructure.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Fast Insights
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Analyze aerodynamics from the initial concept phase,
                            using early surface models, up to production-ready
                            CAD designs.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          From Concept to Production
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Leverage "Design Advice" for tailored guidance on
                            geometry adjustments or let "Morphing" automatically
                            reshape your 3D model.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Automated Design Optimization
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Engage with AirShaper experts to shorten design
                            cycles.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Expert Support
                        </span>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card has-icon h-100">
                <div className="card-body">
                  <FontAwesomeIcon
                    icon={faHandHoldingDollar}
                    size="5x"
                    style={{ top: 35, right: 10, opacity: 0.1, zIndex: -1 }}
                    className="position-absolute text-primary"
                  />
                  <h3 className="card-title">Cost-Effective Solution</h3>
                  <ul className="pt-2">
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Avoid the high costs of CFD software licenses and
                            maintenance.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          No License Fees
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Pay per simulation using a straightforward credit
                            system.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Flexible Plans
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Leverage high-performance computing power without
                            the need for costly hardware investments.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          No Hardware Investment
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Our automated platform handles all complexities,
                            saving you the high costs of hiring a specialized
                            simulation expert.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          No Specialist Needed
                        </span>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card has-icon h-100">
                <div className="card-body">
                  <FontAwesomeIcon
                    icon={faBullseyeArrow}
                    size="5x"
                    style={{ top: 45, right: 10, opacity: 0.1, zIndex: -1 }}
                    className="position-absolute text-primary"
                  />
                  <h3 className="card-title">Industry-Proven Accuracy</h3>
                  <ul className="pt-2">
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Powered on OpenFOAM, the industry's leading
                            open-source CFD software
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Built on OpenFOAM
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Benchmarked through independent customer and
                            industry validation cases.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Industry-Validated
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Featuring adaptive mesh refinement and convergence
                            detection, developed in-house and published in a
                            peer-reviewed research paper.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Advanced Algorithms
                        </span>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-3 mb-3 mb-lg-0">
              <div className="card has-icon h-100">
                <div className="card-body">
                  <FontAwesomeIcon
                    icon={faWandMagicSparkles}
                    size="5x"
                    style={{ top: 35, right: 10, opacity: 0.1, zIndex: -1 }}
                    className="position-absolute text-primary"
                  />
                  <h3 className="card-title">Effortless User Experience</h3>
                  <ul className="pt-2">
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Launch a simulation in under 5 minutes with our
                            fully automated CFD workflow.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Setup under 5 minutes
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Use complex, non-watertight 3D models directly — no
                            tedious CAD model repair or simplification needed.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          No CAD preparation
                        </span>
                      </OverlayTrigger>
                    </li>
                    <li>
                      <OverlayTrigger
                        key="top"
                        placement={isMobile ? 'top' : 'right'}
                        trigger={['hover', 'focus', 'click']}
                        overlay={
                          <Tooltip id="tooltip-top" className="text-left">
                            Analyze detailed results in 3D right in your
                            browser.
                          </Tooltip>
                        }
                      >
                        <span className="d-inline text-decoration-underline-dotted">
                          Interactive 3D Results
                        </span>
                      </OverlayTrigger>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <a
              className="btn btn-primary btn-lg"
              href="https://app.airshaper.com/plans"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Started
            </a>
          </div>
        </div>
      </section>
    </>
  )
}
